
'use strict'
angular.module('cb.activity-log-service', [])
.factory('ActivityLogService', ["ApiService", function (ApiService) {
    var ActivityLogService = {

        getActivityLog: function (data) {
            try {
                var url = '/webapi/api/v1/activitylog/' + data.Id;
                return ApiService.promisePost(url, data);
            }
            catch (e) {
                console.error('Error!', e);
            }
        }
    }

    return ActivityLogService;
}]);